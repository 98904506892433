// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  BottomNavigation, 
  BottomNavigationAction,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Container,
  CssBaseline,
} from '@material-ui/core'
import { Menu, ArrowBack, ChevronRight, NotificationsNoneOutlined, Close} from '@material-ui/icons';
import {isMobile} from 'react-device-detect'
const theme = createTheme({

  overrides:{
      MuiBottomNavigation:{
         root:{
          position:'fixed',
          bottom:0,
          backgroundColor: "#000",
           "&.bottomNav":{
            justifyContent:'space-around',
            width:'100%',
              height:'10%',
           }
          }
      },
      MuiBottomNavigationAction:{
          root:{
              minWidth:'60px',
              maxWidth:'150px',
              color:"#64748B !important",
              
              "&$selected": {
                // "backgroundColor": "red !important",
                color: "#fff !important",
                fontWeight:700,
                filter: "brightness(0) invert(1) !important"
              }
          },
          label:{
              paddingTop:'3px',
              fontSize:'1em !important',
          },
                 
      },
      MuiDrawer:{
        root:{
          '&.drawerMenu > .MuiPaper-root':{
            width:'80%',
          }
        }
      }
  }
});
// Customizable Area End

import BottomNavigationBarController, {
  Props
} from "./BottomNavigationBarController";

export default class BottomNavigationBar extends BottomNavigationBarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"md"} style={{padding:'0px'}}>

        <AppBar
        position="fixed"
        style={{padding:'5px 0px', justifyContent:'space-between',backgroundColor:'#181719',boxShadow:'none'}}
        // className={clsx(classes.appBar, {
        //   [classes.appBarShift]: open,
        // })}
      >
        <Toolbar>
        <Grid container >
        <Grid  xs item style={{display:'grid',justifyContent:'start'}}>
        { this.state.navigationValue == 'home' 
          ?
          <IconButton
            aria-label="open drawer"
            onClick={this.handleDrawerOpen}
            edge="start"
            // className={clsx(classes.menuButton, open && classes.hide)}
          >
            <Menu style={{fill: "#fff"}}/>
          </IconButton>
          : 
          <IconButton data-test-id="backArrowBtn" style={{padding:'0px'}} onClick={()=>this.navigateBack()}>
          <ArrowBack style={{height:'24px',width:'24px',color:'black'}}/>
        </IconButton>
          }
        </Grid>
          <Grid item  xs={9} sm style={{textAlign:'center'}}>
          {this.state.navigationValue === 'home' && <img src={require('../assets/image_logo.png')} style={{height:'3em', width:'7.6em', filter: "invert()"}}/>}

          </Grid>
          <Grid item xs  style={{justifyContent:'end',display:'grid'}} container>
          {this.state.navigationValue === 'home' && <IconButton
            aria-label="open drawer"
            // onClick={this.handleDrawerOpen}
            edge="start"
            // className={clsx(classes.menuButton, open && classes.hide)}
          >
            <NotificationsNoneOutlined style={{fill: "#fff"}}/>
          </IconButton>
          }
          </Grid>
        </Grid>
          
        </Toolbar>
      </AppBar>
      <Drawer
        className="drawerMenu"
        variant="persistent"
        anchor="left"
        open={this.state.drawerOpen}
        style={{width:'80%', borderRadius: 0}}
        BackdropProps={{style:{backgroundColor:'red'}}}
        PaperProps={{style: {backgroundColor: "#010101CC", borderRadius: 0}}}
        // classes={{
        //   paper: classes.drawerPaper,
        // }}
      >
        <div style={{display:'flex',justifyContent:'space-between',padding:'20px 0px 5px'}}>
          <img src={require('../assets/image_logo.png')} style={{filter: 'invert(1)',height:'3em',width:'7.6em',marginLeft:'25px'}}/>
          <IconButton onClick={this.handleDrawerClose}>
            <Close htmlColor="#fff"/>
          </IconButton>
        </div>
        {/* <Divider /> */}
        <List style={{color: "#fff"}}>
          {this.state.drawerMenu.map((text, index) => (
            <ListItem key={`${text.pageName}${index}item`} onClick={()=>this.navigateToPage(text.pagePath,text.pageName)} button style={{paddingRight:'10px',paddingLeft:'32px'}}>
              <ListItemText primary={text.pageName} /> 
              <ListItemIcon style={{minWidth:'10px', color: "#fff"}}><ChevronRight /></ListItemIcon>
            </ListItem>
          ))}
        </List>
       
      </Drawer>
      <div style={{
      // display: 'flex',
      position: 'relative',
      // overflowX: 'hidden',
      // overflowY: 'hidden'
    }}>
      <CssBaseline />
        <main style={{height:isMobile ? '100%' : '100vh'}}>
          {this.props.children}
        </main>
        </div>
        <BottomNavigation value={this.state.navigationValue} showLabels className='bottomNav' >
          {this.state.bottomTabs.map((tab) => {
            return (
              <BottomNavigationAction
                key={`${tab.value}`}
                onClick={() => { this.navigateToPage(tab.path, tab.value) }}
                value={tab.value}
                label={tab.label}
                icon={<img style={{ height: '24px', width: '24px' }} src={this.state.navigationValue == tab.value ? tab.selected_icon : tab.icon} />}
              />
            )
          })}
        </BottomNavigation>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
 
};
// Customizable Area End
